<template>
  <v-app color="contentBackgroundColor">
    <router-view class="main-content" />
    <v-dialog
      v-model="showGlobalDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text class="text-center">Please refresh the page to reload data.</v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              color="primary"
              small
              @click="handleRefresh"
            >
              <v-icon left>mdi-refresh</v-icon>
              Refresh Again
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

  let $routePermissions = JSON.parse(localStorage.getItem('routePermissions')) || []
  let $getPermissions = JSON.parse(localStorage.getItem('getPermissions')) || []
  
  export default {
    name: 'app',
    metaInfo: {
      titleTemplate: (titleChunk) => {
        // If undefined or blank then we don't need the hyphen
        return titleChunk ? `${titleChunk} | Template` : 'Template';
      }
    },
    data() {
      return {};
    },
    computed: {
      // token() { return this.$store.getters['authentication/getToken']; }
    },
    watch: {
      // token(to, from) {
      //   console.log(to, from);
      //   // if (!to) {}
      // }
    },
    methods: {
      initiate() {
        this.events();
        this.checkToken();
        this.setTheme();
      },
      events() {
        this.$eventBus.$on('clear-data', this.clearData);

        // Window Storage Event Listener
        window.addEventListener('storage', (event) => {
          // console.log(event);
          switch(event.key) {
            case 'isLogin':
              setTimeout(() => {
                if (Number(event.newValue)) {
                  this.$router.push({ name: 'main-view' });
                } else {
                  this.clearData();
                }
              }, 100);
              break;
            case 'isDarkMode':
              this.$vuetify.theme.dark = !!Number(event.newValue);
              break;
            case 'routePermissions':
              if(event.newValue === null){
                setTimeout(() => {
                  // this.showGlobalDialog = true
                }, 100);
              }
              break;
            case 'getPermissions':
              if(event.newValue === null){
                setTimeout(() => {
                  // this.showGlobalDialog = true
                }, 100);
              }
              break;
          }
        });
      },
      checkToken() {
        const token = this.$cookies.get('token');
        if (token) { this.$store.dispatch('authentication/setToken', token); }
      },
      setTheme() {
        setTimeout(() => {
          let isDarkMode = !!Number(window.localStorage.getItem('isDarkMode'));
          // Detect System Dark Mode
          // if (!!window && !!window.matchMedia && !Number(window.localStorage.getItem('isDarkMode'))) {
          //   isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          //   window.localStorage.setItem('isDarkMode', Number(isDarkMode));
          // } else {
          //   isDarkMode = !!Number(window.localStorage.getItem('isDarkMode'));
          // }
          this.$vuetify.theme.dark = isDarkMode;
        });
      },
      clearData() {
        this.$store.dispatch('clearState');
        this.$cookies.remove('token');
        this.$api.main.setAuthorization(undefined);
        window.localStorage.setItem('isLogin', 0);
        this.$router.push({ name: 'login' });
        localStorage.removeItem('routePermissions')
        localStorage.removeItem('getPermissions')
        localStorage.removeItem('userData')
      },
      handleRefresh(){
        localStorage.removeItem('routePermissions')
        localStorage.removeItem('getPermissions')
        localStorage.removeItem('userData')
        this.clearData()
        setTimeout(() => {
          window.location.href = ""
        },1000)
      }
    },
    created() {
      // if($routePermissions[0]?.users == true || $getPermissions[0]?.maindata[0]?.child[0]?.permit == true){
      //   this.showGlobalDialog = false
      // }else{
      //   this.showGlobalDialog = true
      // }
      const token = this.$cookies.get('token');
      if (token) {this.handlePermissions();}
      this.initiate();
    },
    beforeDestroy() {
      this.$eventBus.$off('clear-data');
    }
  };
</script>

<style lang="scss">
  @import '@/assets/css/main.scss';
  @import url('https://fonts.googleapis.com/css?family=Material+Icons');
</style>
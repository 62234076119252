'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    coob_upload_list: undefined,
    coob_upload_details: undefined
  },
  getters: {
    getCoobUploadList: state => state.coob_upload_list,
  },
  mutations: {
    SET_COOB_UPLOAD_LIST(state, payload) {
        state.coob_upload_list = payload;
    },
    SET_COOB_UPLOAD_DETAILS(state, payload) {
        state.coob_upload_details = payload;
    },
    CLEAR_STATE(state) {
        state.coob_upload_list = undefined;
        state.coob_upload_details = undefined;
    }
  },
  actions: {
    fetchUploadCoob(context, payload) {
        const { config, apiCancelToken } = payload || {};
        return new Promise((resolve, reject) => {
          mainApi.getData({ url: '/api/admin/coob-list', config, apiCancelToken }).then(
            (response) => {
              // console.log(response.data);
              context.commit('SET_COOB_UPLOAD_LIST', response.data);
              resolve(response.data);
            }
          ).catch(
            (error) => {
              // console.log(error);
              context.commit('SET_COOB_UPLOAD_LIST', undefined);
              context.dispatch('error', error, { root: true });
              reject(error);
            }
          );
        });
    },
    deleteCoob(context, payload) {
        const { id, config, apiCancelToken } = payload || {};
        return new Promise((resolve, reject) => {
          mainApi.deleteData({ url: `/api/admin/coob-delete/${id}`, config, apiCancelToken }).then(
            (response) => {
              // console.log(response);
              resolve(response);
            }
          ).catch(
            (error) => {
              // console.log(error);
              context.dispatch('error', error, { root: true });
              reject(error);
            }
          );
        });
    },
    uploadFileCoob(context, payload) {
        const { data, config, apiCancelToken } = payload || {};
        return new Promise((resolve, reject) => {
            mainApi.postData({ url: '/api/admin/import-coob', data, config, apiCancelToken }).then(
            (response) => {
                // console.log(response);
                resolve(response);
            }
            ).catch(
            (error) => {
                // console.log(error);
                context.dispatch('error', error, { root: true });
                reject(error);
            }
            );
        });
    }
  }
};
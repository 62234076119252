'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    users_list: undefined,
    user_details: undefined,
    sales_list: undefined,
    distro_list: undefined,
    user_profile: undefined
  },
  getters: {
    getUsersList: state => state.users_list,
    getSalesList: state => state.sales_list,
    getUserDetails: state => state.user_details,
    getDistroList: state => state.distro_list,
    getPermitsList: state => state.permits_list,
    getUserProfile: state => state.user_profile
  },
  mutations: {
    SET_PERMITS_LIST(state, payload){
      state.permits_list = payload;
    },
    SET_DISTRO_LIST(state, payload){
      state.distro_list = payload;
    },
    SET_SALES_LIST(state, payload) {
      state.sales_list = payload;
    },
    SET_USERS_LIST(state, payload) {
        state.users_list = payload;
    },
    SET_USER_DETAILS(state, payload) {
        state.user_details = payload;
    },
    SET_PROFILE(state, payload){
        state.getUserProfile = payload;
    },
    CLEAR_STATE(state) {
        state.users_list = undefined;
        state.user_details = undefined;
    }
  },
  actions: {
    fetchUsers(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/user-list', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_USERS_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_USERS_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchDistroChannel(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/select-distro', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_DISTRO_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_DISTRO_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchSalesPerson(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/sales-list', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_SALES_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_SALES_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    findUser(context, payload) {
      const { id, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: `/user/${id}`, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_USER_DETAILS', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_USER_DETAILS', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    addUser(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/add-user', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    setUser(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.putData({ url: `/api/admin/save-user/${data.id}`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    deleteUserLocation(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: `/api/admin/user-location-delete`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    userActivation(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: `/api/admin/activate/${data.id}/${data.action}`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    deleteUser(context, payload) {
      const { id, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.deleteData({ url: `/api/admin/delete-user/${id}`, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchSalesOffices(context, payload) {
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/user-location-modal-list' }).then(
          (response) => {
            // console.log(response.data);
            //context.commit('SET_USERS_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_USERS_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    userActiveAndInactive(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: `/api/admin/count-status`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    userPrivileges(context, payload) {
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/user-privileges' }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_PERMITS_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_PERMITS_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    deleteUserDevice(context, payload) {
      const { id, config, apiCancelToken } = payload || {};
      console.log(id)
      return new Promise((resolve, reject) => {
        mainApi.deleteData({ url: `/api/admin/delete-device/${id}`, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    userResetPasswordCMS(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: `/api/admin/cms-reset-password/${data.id}`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchUserProfile(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/user-details', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_PROFILE', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_PROFILE', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    userProfile(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.putData({ url: `/api/admin/user-profile`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
  }
};
'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    route_prod_list: undefined,
    route_prod_details: undefined
  },
  getters: {
    getRouteProdList: state => state.route_prod_list,
  },
  mutations: {
    SET_ROUTE_PROD_LIST(state, payload) {
        state.route_prod_list = payload;
    },
    SET_ROUTE_PROD_DETAILS(state, payload) {
        state.route_prod_details = payload;
    },
    CLEAR_STATE(state) {
        state.route_prod_list = undefined;
        state.route_prod_details = undefined;
    }
  },
  actions: {
    fetchRouteProd(context, payload) {
        const { config, apiCancelToken } = payload || {};
        return new Promise((resolve, reject) => {
          mainApi.getData({ url: '/api/admin/route-productivity', config, apiCancelToken }).then(
            (response) => {
              // console.log(response.data.route_prod);
              context.commit('SET_ROUTE_PROD_LIST', response.data);
              resolve(response.data.route_prod);
            }
          ).catch(
            (error) => {
              // console.log(error);
              context.commit('SET_ROUTE_PROD_DETAILS', undefined);
              context.dispatch('error', error, { root: true });
              reject(error);
            }
          );
        });
    }
  }
};
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const AuthView = () => import(/* webpackChunkName: "auth-view" */ '@/views/AuthView');
const Login = () => import(/* webpackChunkName: "login" */ '@/components/Views/AuthView/Login');

const MainView = () => import(/* webpackChunkName: "main-view" */ '@/views/MainView');

// Route Productivity
const RouteProductivity = () => import('@/components/Views/MainView/RouteProductivity/Index');

// Users Management
const Administrator = () => import('@/components/Views/MainView/Administrator/Index');
const Salesman = () => import('@/components/Views/MainView/Salesman/Index');

// Settings
const GeneralSettings = () => import('@/components/Views/MainView/Settings/GeneralSettings/Index');
// const SalesLocation = () => import('@/components/Views/MainView/Settings/SalesLocation/Index');
const Roles = () => import('@/components/Views/MainView/Settings/Roles/Index');
const RealtimeTracking = () => import('@/components/Views/MainView/RealtimeTracking/Index');
const KPIReports = () => import('@/components/Views/MainView/KPIReports/Index');
const UploadCoob = () => import('@/components/Views/MainView/UploadCoob/Index');
const UserProfile = () => import('@/components/Views/MainView/Settings/UserProfile/Index');

const mainViewRedirect = { name: 'route-productivity' };

const routes = [
  {
    path: '/auth',
    name: 'auth-view',
    redirect: { name: 'login' },
    component: AuthView,
    meta: { requiresAuthentication: false },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { requiresAuthentication: false }
      }
    ]
  },
  {
    path: '/',
    name: 'main-view',
    redirect: mainViewRedirect,
    component: MainView,
    meta: { requiresAuthentication: true },
    children: [
      {
        path: '/admin/users',
        name: 'users',
        component: Administrator,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/salesman',
        name: 'salesman',
        component: Salesman,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/realtime-tracking',
        name: 'realtime-tracking',
        component: RealtimeTracking,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/upload-coob',
        name: 'upload-coob',
        component: UploadCoob,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/kpi-reports',
        name: 'kpi-reports',
        component: KPIReports,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/general-settings',
        name: 'general-settings',
        component: GeneralSettings,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/roles',
        name: 'roles',
        component: Roles,
        meta: { requiresAuthentication:  true }
      },
      {
        path: '/admin/route-productivity',
        name: 'route-productivity',
        component: RouteProductivity,
        meta: { requiresAuthentication: true }
      },
      {
        path: '/admin/profile',
        name: 'user-profile',
        component: UserProfile,
        meta: { requiresAuthentication: true }
      },
    ]
  },
  // {
  //   path: '/template',
  //   name: 'template',
  //   // route level code-splitting
  //   // this generates a separate chunk (template.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "template" */ '@/views/template')
  // },
  { // For 404 Page
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: { path: '/' }
    // component: () => import(/* webpackChunkName: "template" */ '@/views/PathNotFound')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // console.log({ to, from, next });
  
  // Cancel current API call in route change
  if (to.name != from.name) { router.app.$api.main.cancelCurrentApiCall();}

  const token = router.app.$cookies.get('token');
  
  // console.log(token);

  if (to.meta.requiresAuthentication) {
    if (token) {
      if (!router.app.$api.main.getAuthorization()) { router.app.$api.main.setAuthorization(window.atob(token)); } // Decode Base64
      next();
    } else {
      router.app.$cookies.remove('token');
      router.app.$api.main.setAuthorization(undefined);
      window.localStorage.setItem('isLogin', 0);
      next({ name: 'login', query: { redirect: ((to.name != mainViewRedirect.name) ? to.name : undefined) } });
    }
  } else {
    if (to.matched.length == 0) {
      next();
    } else if (token) {
      if (!router.app.$api.main.getAuthorization()) { router.app.$api.main.setAuthorization(window.atob(token)); } // Decode Base64
      next({ name: 'main-view' });
    } else {
      router.app.$cookies.remove('token');
      window.localStorage.setItem('isLogin', 0);
      next();
    }
  }
});

export default router;
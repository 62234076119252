'use strict';

window.permits = [];
let $routePermissions = JSON.parse(localStorage.getItem('routePermissions')) || []
let $getPermissions = JSON.parse(localStorage.getItem('getPermissions')) || []

const global = {
  data() {
    return {
      confirmDialog: { show: false, loading: false, title: '', message: '', buttonText: '', buttonTextColor: '', buttonBGColor: '', action: '', data: {}, callBack: () => {} },
      userActive: 0,
      userInactive: 0,
      globalPermits: [],
      showGlobalDialog: false
    };
  },
  filters: {},
  watch: {
    "globalPermits"(perms){
      permits = perms;
    }
  },
  computed: {
    userPermissions(){
      // Conntected to Application Navigation Sidebard menu
      return this.getPermissions = [{
        maindata: [
          {
            to: 'user-module',
            permit: this.handleHasAccess('user modules'),
            child: [
              {
                to: 'users',
                permit: this.handleHasAccess('admin modules')
              },
              {
                to: 'salesman',
                permit: this.handleHasAccess('salesperson modules')
              },
            ]
          },
          {
            to: 'realtime-tracking',
            permit: this.handleHasAccess('realtime_tracking modules')
          },
          {
            to: 'sfa-web-report',
            permit: this.handleHasAccess('sfa_web_reports modules'),
            child: [
              {
                to: 'upload-coob',
                permit: this.handleHasAccess('upload_coob modules')
              },
              {
                to: 'kpi-reports',
                permit: this.handleHasAccess('kpi_reports modules')
              },
            ]
          },
          {
            to: 'settings',
            permit: this.handleHasAccess('settings modules'),
            child: [
              {
                to: 'general-settings',
                permit: this.handleHasAccess('general settings')
              },
              {
                to: 'roles',
                permit: this.handleHasAccess('role settings')
              },
            ]
          }
        ]
      }];
    },
    userAuthRequired(){
      // Dont change the arrangement of the following associate array
      return [
        {
          'users': this.handleHasAccess('admin modules'),
        },
        {
          'salesperson': this.handleHasAccess('salesperson modules'),
        },
        {
          'realtime_tracking': this.handleHasAccess('realtime_tracking modules'),
        },
        {
          'upload_coob': this.handleHasAccess('upload_coob modules'),
        },
        {
          'kpi_reports': this.handleHasAccess('kpi_reports modules'),
        },
        {
          'settings': this.handleHasAccess('settings modules'),
        },
        {
          'general_settings': this.handleHasAccess('general settings'),
        },
        {
          'roles': this.handleHasAccess('role settings'),
        },
        {
          'sfa_web_report': this.handleHasAccess('sfa_web_reports modules'),
        },
      ];
    }
  },
  methods: {
    getParent(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name === name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return false;
    },
    generateApiCancelToken() { return `api_cancel_token_${Math.floor(Math.random() * Date.now()).toString(36).slice(2)}`; },
    confirmAction(payload) {
      const { title, message, buttonText, buttonTextColor, buttonBGColor, action, data, callBack } = payload || {};
      this.confirmDialog = { show: true, loading: false, title, message, buttonText, buttonTextColor, buttonBGColor, action, data, callBack };
    },
    handleActiveAndInactiveCount(tableType){
      this.$store.dispatch('users/userActiveAndInactive', {
          data: {
              "tableType": tableType
          }
      }).then(
        (response) => {
          this.userActive = response.active;
          this.userInactive = response.inactive;
        }
      ).catch(
        (error) => {
          console.log(error);
          this.$notify({
            group: 'app-notifications',
            type: 'error',
            title: 'Users',
            text: error.response.data.data
          });
        }
      );
    },
    handleHasAccess(name) {
        if(permits.length != 0){
        // console.log(permits)
          let p = false;
          permits.map((item) => {
              if (item.name === name) {
                  p = item.has_access == "1";
                  return false;
              }
            })
          return p;
        }
    },
    handlePermissions() {
      this.$store.dispatch('users/userPrivileges').then(
        (response) => {
          this.globalPermits = response;
          // if($routePermissions[0]?.users == true && $getPermissions[0]?.maindata[0]?.child[0]?.permit == true){
          //   this.showGlobalDialog = false
          // }else{
          //   this.showGlobalDialog = true
          // }
          // console.log(this.globalPermits)
        }
      ).catch(
        (error) => {
          console.log(error);
          this.showGlobalDialog = true
          this.$notify({
            group: 'app-notifications',
            type: 'error',
            title: 'Users',
            text: error.response.data.data
          });
        }
      );
    },
    handleSidebarMenusPermit(){
      let $getPermissions = JSON.parse(localStorage.getItem('getPermissions')) || []
      if($getPermissions.length == 0){
        localStorage.setItem('routePermissions', JSON.stringify(this.userAuthRequired))
        return localStorage.setItem('getPermissions', JSON.stringify(this.userPermissions))
      }else{
        return this.userPermission
      }
    }
  }
};

export default global;
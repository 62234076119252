'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    general_list: undefined,
    general_list: undefined
  },
  getters: {
    getGeneralList: state => state.general_list,
    getGeneralDetails: state => state.general_list,
  },
  mutations: {
    SET_GENERAL_LIST(state, payload) {
        state.general_list = payload;
    },
    SET_GENERAL_DETAILS(state, payload) {
        state.general_details = payload;
    },
    CLEAR_STATE(state) {
        state.general_list = undefined;
        state.general_details = undefined;
    }
  },
  actions: {
    fetchGeneralSetting(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/general-settings', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_GENERAL_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_GENERAL_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    findGeneralSetting(context, payload) {
      const { id, data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: `/api/admin//${id}`, config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_GENERAL_DETAILS', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_GENERAL_DETAILS', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    saveAdminPassword(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/update-admin-pwd', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    saveAppVersion(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/update-app-version', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    saveBusinessHrs(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/update-business-hrs', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    saveSelectDay(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/update-select-day', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    saveSelectTime(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/update-select-time', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    saveAllowedOrder(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/update-allowed-order', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    }
  }
};
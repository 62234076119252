'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    realtime_tracking_list: undefined,
    realtime_tracking_details: undefined
  },
  getters: {
    getRealTimeTrackingList: state => state.realtime_tracking_list,
  },
  mutations: {
    SET_REALTIME_TRACKING_LIST(state, payload) {
        state.realtime_tracking_list = payload;
    },
    SET_REALTIME_TRACKING_DETAILS(state, payload) {
        state.realtime_tracking_details = payload;
    },
    CLEAR_STATE(state) {
        state.realtime_tracking_list = undefined;
        state.realtime_tracking_details = undefined;
    }
  },
  actions: {
    fetchRealtimeTracking(context, payload) {
        const { config, apiCancelToken } = payload || {};
        return new Promise((resolve, reject) => {
          mainApi.getData({ url: '/api/admin/realtime-tracking-list', config, apiCancelToken }).then(
            (response) => {
              // console.log(response.data);
              context.commit('SET_REALTIME_TRACKING_LIST', response.data);
              resolve(response);
            }
          ).catch(
            (error) => {
              // console.log(error);
              context.commit('SET_REALTIME_TRACKING_DETAILS', undefined);
              context.dispatch('error', error, { root: true });
              reject(error);
            }
          );
        });
    },
    fetchSaleRoute(context, payload) {
        const { config, apiCancelToken } = payload || {};
        return new Promise((resolve, reject) => {
          mainApi.getData({ url: '/api/admin/routes-list', config, apiCancelToken }).then(
            (response) => {
            //   console.log(response.data);
              resolve(response.data);
            }
          ).catch(
            (error) => {
              // console.log(error);
              context.dispatch('error', error, { root: true });
              reject(error);
            }
          );
        });
    }
  }
};
'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    roles_list: undefined,
    role_details: undefined
  },
  getters: {
    getRolesList: state => state.roles_list,
    getRoleDetails: state => state.role_details,
    getPermitsList: state => state.permits_list,
  },
  mutations: {
    SET_ROLES_LIST(state, payload) {
        state.roles_list = payload;
    },
    SET_ROLE_DETAILS(state, payload) {
        state.role_details = payload;
    },
    CLEAR_STATE(state) {
        state.roles_list = undefined;
        state.role_details = undefined;
    },
    SET_PERMITS_LIST(state, payload) {
        state.permits_list = payload;
    }
  },
  actions: {
    fetchRoles(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/role-list', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_ROLES_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_ROLES_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchRoleSelection(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/select-roles', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_ROLES_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_ROLES_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchRolesPermit(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/permissions', config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_PERMITS_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_PERMITS_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    findRole(context, payload) {
      const { id, data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: `/api/admin/edit-role/${id}`, config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_ROLE_DETAILS', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_ROLE_DETAILS', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    addRole(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.postData({ url: '/api/admin/add-role', data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    setRole(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.putData({ url: `/api/admin/save-role/${data.id}`, data, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    deleteRole(context, payload) {
      const { id, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.deleteData({ url: `/api/admin/delete-role/${id}`, config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchUserProfileRoleSelection(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/api/admin/all-roles', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_ROLES_LIST', response.data);
            resolve(response.data);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_ROLES_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    }
  }
};